import { remove, save, load } from 'react-cookies';

const cookieName = '__current_session';
const maxExpiry = 60 * 60;

export function getToken(): string | undefined {
  return load(cookieName);
}

export function saveToken(token: string, expiry: number | null) {
  const expiryPeriodToUse = expiry || maxExpiry;
  const expiryDate = new Date();
  expiryDate.setSeconds(expiryDate.getSeconds() + expiryPeriodToUse);

  return save(cookieName, token, {
    path: '/',
    maxAge: expiryPeriodToUse,
    expires: expiryDate,
    secure: true,
    sameSite: true,
  });
}

export function removeToken() {
  return remove(cookieName, { path: '/' });
}
