import { Form, Formik, FormikHelpers } from 'formik';
import { emailSchema } from '../../utils/formSchema';
import ActivateAccountEmailInput from '../activate-account-email-input/activate-account-email-input';
import { ActivateAccountFormInput } from '../../interfaces/interfaces';
import { useMutation } from '@apollo/client';
import { VerifyEmailDocument } from '../../queries';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function ActivateAccount() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };
  const clientId = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');

  const [verifyEmail] = useMutation(VerifyEmailDocument);

  const handleSubmit = async (
    values: ActivateAccountFormInput,
    { setErrors }: FormikHelpers<ActivateAccountFormInput>
  ) => {
    if (!clientId || !redirectUri) {
      setErrors({
        email: 'Unexpected error.',
      });
      return Promise.resolve();
    }

    return verifyEmail({
      variables: {
        email: values.email,
        clientId,
        redirectUri,
      },
    })
      .then((_) => {
        // FIXME: What to do if the user is already activated?
        // if (res.data?.verifyEmail) {
        //   navigate(`/verify-email?${searchParams.toString()}`);
        // }
        navigate(`/verify-email?${searchParams.toString()}`, {
          state: { from: 'activate' },
        });
      })
      .catch(() => {
        setErrors({
          email: 'Unexpected error.',
        });
      });
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={emailSchema}
      onSubmit={handleSubmit}
      validateOnMount={false}
    >
      <Form>
        <ActivateAccountEmailInput />
      </Form>
    </Formik>
  );
}

export default ActivateAccount;
