import { FieldMetaProps } from 'formik';
import classnames from 'classnames';

export type FieldErrorMessageProps<T> = {
  meta: FieldMetaProps<T>;
  shouldShowError?: (meta: FieldMetaProps<T>) => boolean;
};

const defaultShowError = <T,>(meta: FieldMetaProps<T>) =>
  !!meta.error && meta.touched;

export function FieldErrorMessage<T>({
  meta,
  shouldShowError = defaultShowError,
}: FieldErrorMessageProps<T>) {
  const isHidden = !shouldShowError(meta);
  return (
    <div
      data-testid="error-message"
      style={{
        visibility: shouldShowError(meta) ? 'visible' : 'hidden',
      }}
      className={classnames(
        'error-message',
        isHidden ? 'invisible' : 'visible'
      )}
    >
      {meta.error ? meta.error : <>&nbsp;</>}
    </div>
  );
}
