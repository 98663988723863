import React, { KeyboardEvent } from 'react';
import { FormikErrors, FormikTouched, useField } from 'formik';
import { SignInFormInput } from '../../interfaces/interfaces';
import { Button, Col, Row } from 'react-bootstrap';
import TextField from '../text-field/text-field';
import { emailSchema } from '../../utils/formSchema';

export type VerifyEmailInputProps = {
  handleSubmit: () => void;
  isLoading: boolean;
  errors: FormikErrors<SignInFormInput>;
  touched: FormikTouched<SignInFormInput>;
};

export function VerifyEmail({
  handleSubmit,
  isLoading,
}: VerifyEmailInputProps) {
  const [, meta] = useField<string>('email');
  const { value } = meta;

  const isEmailValid = () => {
    return !isLoading && emailSchema.isValidSync({ email: value });
  };

  return (
    <>
      <div style={{ height: '38px' }}></div>
      <Row>
        <Col>
          <p className={'header'}>Sign in</p>
          <p className={'subtext'}>Login to your SPINS account to continue</p>
          <TextField
            label="Email"
            name="email"
            onKeyUp={(event: KeyboardEvent) => {
              if (event.key === 'Enter' && isEmailValid()) {
                handleSubmit();
              }
            }}
          />
        </Col>
      </Row>
      <Row className={'form-button-row'}>
        <Col>
          <Button
            disabled={!isEmailValid()}
            color="primary"
            id="button-next"
            onClick={handleSubmit}
            style={{ width: '100%' }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default VerifyEmail;
