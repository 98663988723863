import styles from '../../app.module.scss';
import { Footer, SimpleHeader } from '@spins-gcp/shared-components-react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

export function MainLayout() {
  return (
    <div className={styles['window-body']}>
      <SimpleHeader pageName={'Welcome'} theme={'light'} />
      <Container>
        <Row className={'justify-content-center align-items-center'}>
          <Col xs={4}>
            <div className={`${styles['main-body-row']} login-form`}>
              <Row style={{ flexWrap: 'nowrap' }}>
                <Col className={'align-self-center'}>
                  <Outlet />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
