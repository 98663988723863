import { Form } from 'react-bootstrap';
import React, { KeyboardEvent } from 'react';
import { Field, FieldProps } from 'formik';
import { FieldErrorMessage } from '../field-error-message/field-error-message';

export type TextFieldProps = {
  label: string;
  name: string;
  onKeyUp: (event: KeyboardEvent) => void;
};

export function TextField({ name, label, onKeyUp }: TextFieldProps) {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps<string>) => (
        <Form.Group controlId={name}>
          <Form.Label column={'sm'}>{label}</Form.Label>
          <Form.Control autoFocus type="text" {...field} onKeyUp={onKeyUp} />
          <FieldErrorMessage meta={meta} />
        </Form.Group>
      )}
    </Field>
  );
}

export default TextField;
