import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './password-input.module.scss';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrowLeft.svg';
import PasswordField from '../../password-field/password-field';

export interface PasswordInputProps {
  handleBack: () => void;
  email: string;
  isLoading: boolean;
  handleForgotPassword: (email: string) => void;
}

export function PasswordInput({
  email,
  handleBack,
  isLoading,
  handleForgotPassword,
}: PasswordInputProps) {
  return (
    <>
      <Button variant={'text'} style={{ paddingLeft: 0 }} onClick={handleBack}>
        <span>
          <ArrowLeft className={styles['left-arrow']} /> Back
        </span>
      </Button>
      <Row>
        <Col>
          <p className={'header'}>Welcome back!</p>
          <p className={'subtext'}>Login using {email}</p>
          <PasswordField
            handleForgotPassword={() => {
              handleForgotPassword(email);
            }}
            isLoading={isLoading}
            showFieldError
          />
        </Col>
      </Row>

      <Row className={'form-button-row'}>
        <Col>
          <Button
            disabled={isLoading}
            type={'submit'}
            color="primary"
            style={{ width: '100%' }}
          >
            Sign In
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default PasswordInput;
