import * as Yup from 'yup';

export const signInSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});

export const emailSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(8, 'Should be 8 chars minimum.')
    .matches(/[A-Z]/, 'Should have uppercase letter')
    .matches(/[a-z]/, 'Should have lowercase letter')
    .matches(/[0-9]/, 'Should have a number'),
});

export type PasswordSchema = Yup.InferType<typeof passwordSchema>;

export const minimumLengthSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Should be 8 chars minimum.'),
});

export const uppercaseSchema = Yup.object().shape({
  password: Yup.string().matches(/[A-Z]/, 'Should have uppercase letter'),
});

export const lowercaseSchema = Yup.object().shape({
  password: Yup.string().matches(/[a-z]/, 'Should have lowercase letter'),
});

export const numberSchema = Yup.object().shape({
  password: Yup.string().matches(/[0-9]/, 'Should have a number'),
});
