import decode, { JwtPayload } from 'jwt-decode';

export function isExpired(token: string): boolean {
  try {
    const decoded = decode<JwtPayload>(token);
    return !!decoded.exp && Date.now() >= decoded.exp * 1000;
  } catch {
    return true;
  }
}
