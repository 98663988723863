import styles from './send-email-success.module.scss';
import { Button, Col, Row } from 'react-bootstrap';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { excludeParams } from '../../utils/url-utils';

export function SendEmailSuccess() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const from = location.state?.from ?? 'verify';

  const backTo = searchParams.get('back_to');
  let backUrl;
  try {
    backUrl = backTo
      ? decodeURIComponent(backTo)
      : `/authorize?${searchParams.toString()}`;
  } catch (e) {
    const filteredParams = excludeParams(searchParams, ['back_to']);
    backUrl = `/authorize?${filteredParams.toString()}`;
  }

  const header =
    from === 'forgot' ? 'Reset Password' : 'Please verify your email address';

  return (
    <>
      {from === 'activate' && (
        <Row>
          <Col>
            <p className={'header'}>Activate your account</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <p className={from === 'activate' ? 'subtext' : 'header'}>{header}</p>
        </Col>
      </Row>
      <Row className={`${styles['mail']} gap-3`}>
        <MailIcon />
        <div>We've sent you a verification email</div>
      </Row>
      <Row className={'form-button-row'}>
        <Col>
          <Button href={backUrl} color="primary" style={{ width: '100%' }}>
            Go back to login
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default SendEmailSuccess;
