export function excludeParams(
  searchParams: URLSearchParams,
  excludeKeys: string[]
): URLSearchParams {
  const newParams = new URLSearchParams();
  for (const [key, value] of searchParams.entries()) {
    if (!excludeKeys.includes(key)) {
      newParams.set(key, value);
    }
  }
  return newParams;
}
