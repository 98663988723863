/* istanbul ignore file */

import {
  ApolloClient,
  ApolloError,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  HttpLink,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client';
import fetch from 'cross-fetch';
import {
  ExecutableDefinitionNode,
  OperationDefinitionNode,
} from 'graphql/language/ast';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { logger } from './logging';

export const createSdnClient = (sdnUrl: string, batchSdnCalls = false) => {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: batchSdnCalls
      ? new BatchHttpLink({
          uri: sdnUrl,
          headers: {},
          fetch,
          batchMax: 5,
          batchInterval: 250,
        })
      : new HttpLink({
          uri: sdnUrl,
          headers: {},
          fetch,
        }),
  });
  const mutate = async <
    TData,
    TVariables = OperationVariables,
    TContext = DefaultContext
  >(
    mutation: MutationOptions<TData, TVariables, TContext>
  ): Promise<FetchResult<TData>> => {
    return client.mutate(mutation);
  };

  const queryFn = async <T, TVariables = OperationVariables>(
    query: QueryOptions<TVariables, T>,
    retries = 0,
    maxRetries = 0
  ): Promise<ApolloQueryResult<T>> => {
    const queryName = query.query
      .definitions[0] as ExecutableDefinitionNode as OperationDefinitionNode;
    const name = queryName.name?.value;

    return client.query<T, TVariables>(query).catch(async (e: ApolloError) => {
      if (retries < maxRetries) {
        const currentRetry = retries + 1;
        logger.warn(`Retrying ${name} ${currentRetry}`);
        return queryFn(query, currentRetry, maxRetries);
      } else {
        logger.warn(`Error from ${name}: ${e.message}`);
      }

      throw e;
    });
  };

  return {
    client,
    mutate,
    query: queryFn,
  };
};

export const {
  client,
  mutate: mutation,
  query: queryAuth,
} = createSdnClient(
  process.env['NX_PUBLIC_AUTH_API_URL'] ||
    'https://spins-auth-api-xolbys3uga-uc.a.run.app'
);
