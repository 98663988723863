import React, { useEffect, useState } from 'react';
import PasswordInput from './password-input/password-input';
import { useMutation } from '@apollo/client';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikTouched,
  FormikValues,
} from 'formik';
import { SignInFormInput } from '../../interfaces/interfaces';
import { signInSchema } from '../../utils/formSchema';
import { LoginDocument, VerifyEmailDocument } from '../../queries';
import { logger } from '../../utils/logging';
import { useNavigate, useSearchParams } from 'react-router-dom';
import VerifyEmail from '../verify-email/verify-email';
import { saveToken } from '../../utils/cookie-store';

export function Login() {
  const initialValues: SignInFormInput = {
    email: '',
    password: '',
  };

  const [login] = useMutation(LoginDocument);
  const [verifyEmail] = useMutation(VerifyEmailDocument);

  const [currentStep, setCurrentStep] = useState(1);
  const [token, setToken] = useState<string>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const clientId = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');
  const state = searchParams.get('state');
  useEffect(() => {
    if (token && redirectUri) {
      const url = `${redirectUri}#access_token=${encodeURIComponent(
        token
      )}&state=${state}`;
      logger.info(url);
      window.location.assign(url);
    }
  }, [token, redirectUri, state]);

  const handleForgotPassword = (
    email: string,
    {
      setErrors,
    }: { setErrors: (errors: FormikErrors<SignInFormInput>) => void }
  ) => {
    if (!redirectUri || !clientId) {
      setErrors({
        password: 'Unexpected error.',
      });
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('client_id', clientId);
    searchParams.append('redirect_uri', redirectUri);
    searchParams.append('email', email);

    navigate(`/forgot-password?${searchParams.toString()}`);
  };

  const handleSubmit = async (
    values: SignInFormInput,
    helpers: FormikHelpers<SignInFormInput>
  ): Promise<void> => {
    const { email, password } = values;
    if (!clientId || !redirectUri) {
      helpers.setErrors({
        password: 'Unexpected error.',
      });
      return;
    }

    return login({
      variables: {
        email,
        password,
        clientId,
        redirectUri,
      },
    })
      .then(({ data }) => {
        if (!data?.login) {
          helpers.setErrors({
            password: 'Unexpected error.',
          });
          return;
        }

        if (data.login?.__typename === 'LoginErrors') {
          helpers.setErrors({
            password: data.login.message,
          });
        }

        if (data.login.__typename === 'Token') {
          const { token, expiry } = data.login;
          saveToken(token, expiry);
          setToken(data.login.token);
        }
      })
      .catch(() => {
        helpers.setErrors({
          password: 'Unexpected error.',
        });
      });
  };

  const handleNext = (
    values: FormikValues,
    {
      setErrors,
      setTouched,
    }: {
      setErrors: (errors: FormikErrors<SignInFormInput>) => void;
      setTouched: (touched: FormikTouched<SignInFormInput>) => void;
    }
  ) => {
    const { email } = values;
    if (!clientId || !redirectUri) {
      setErrors({
        email: 'Unexpected error.',
      });
      return Promise.resolve();
    }

    setTouched({
      password: false,
    });

    return verifyEmail({
      variables: {
        email,
        redirectUri,
        clientId,
      },
    })
      .then((res) => {
        logger.info(res);
        if (res.data?.verifyEmail) {
          setCurrentStep(2);
        } else {
          navigate(`/verify-email?${searchParams.toString()}`);
        }
      })
      .catch(() => {
        setErrors({
          email: 'Unexpected error.',
        });
      });
  };

  const handleBack = () => {
    setCurrentStep(1);
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={signInSchema}
      onSubmit={handleSubmit}
      validateOnMount={false}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setSubmitting,
        isSubmitting,
        setErrors,
        setTouched,
      }) => (
        <Form>
          {currentStep === 1 ? (
            <VerifyEmail
              handleSubmit={() => {
                setSubmitting(true);
                handleNext(values, { setErrors, setTouched }).finally(() => {
                  setSubmitting(false);
                });
              }}
              isLoading={isSubmitting}
              errors={errors}
              touched={touched}
            />
          ) : (
            <PasswordInput
              isLoading={isSubmitting}
              handleBack={() => {
                setFieldValue('password', '', false);
                handleBack();
              }}
              email={values.email}
              handleForgotPassword={(email) => {
                setSubmitting(true);
                handleForgotPassword(email, { setErrors });
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}
