import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { removeToken } from '../../utils/cookie-store';

export function Logout() {
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  useEffect(() => {
    if (returnTo) {
      removeToken();
      window.location.replace(returnTo);
    }
  }, [returnTo]);

  return null;
}
