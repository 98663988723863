import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ForgotPasswordDocument } from '../../queries';

export function ForgotPassword() {
  const [searchParams] = useSearchParams();

  const [forgotPassword, { called, loading }] = useMutation(
    ForgotPasswordDocument
  );

  const email = searchParams.get('email');
  const clientId = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');

  useEffect(() => {
    if (email && clientId && redirectUri && !called && !loading) {
      forgotPassword({
        variables: { email, clientId, redirectUri },
      });
    }
  }, [email, clientId, redirectUri, called, loading, forgotPassword]);

  if (!called || loading) {
    return null;
  }

  return (
    <Navigate
      to={`/verify-email?${searchParams.toString()}`}
      state={{ from: 'forgot' }}
    />
  );
}
