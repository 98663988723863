import styles from './password-field.module.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import classnames from 'classnames';
import { ReactComponent as ShowPassword } from '../../../assets/icons/eyeOpen.svg';
import { ReactComponent as HidePassword } from '../../../assets/icons/eyeClosed.svg';
import { FieldErrorMessage } from '../field-error-message/field-error-message';

export interface PasswordFieldProps {
  handleForgotPassword?: () => void;
  isLoading?: boolean;
  showFieldError?: boolean;
}

export function PasswordField({
  handleForgotPassword,
  isLoading,
  showFieldError = false,
}: PasswordFieldProps) {
  const [shouldHidePassword, setShouldHidePassword] = useState(true);
  return (
    <Field name={'password'}>
      {({ field, meta }: FieldProps<string>) => {
        const isInvalid = !!meta.error && meta.touched;
        return (
          <div className={styles['input-wrapper']}>
            <Form.Group controlId={'password'}>
              <Form.Label column={'sm'}>Password</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  autoFocus
                  className={classnames(
                    styles['password-input'],
                    'border-end-0'
                  )}
                  type={shouldHidePassword ? 'password' : 'text'}
                  data-testid="password-input"
                  isInvalid={isInvalid}
                  {...field}
                />
                <Button
                  size="sm"
                  data-testid="toggle-password-button"
                  className={classnames(
                    'border',
                    'border-start-0',
                    isInvalid && 'border-danger'
                  )}
                  variant={'outline'}
                  onClick={() => {
                    setShouldHidePassword(!shouldHidePassword);
                  }}
                >
                  {shouldHidePassword ? <HidePassword /> : <ShowPassword />}
                </Button>
              </InputGroup>
              {showFieldError && <FieldErrorMessage meta={meta} />}
            </Form.Group>
            {handleForgotPassword && (
              <Button
                onClick={handleForgotPassword}
                className={styles['forgot-password']}
                variant={'text'}
                disabled={!!isLoading}
              >
                Forgot your Password?
              </Button>
            )}
          </div>
        );
      }}
    </Field>
  );
}

export default PasswordField;
