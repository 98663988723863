import { Button, Col, Row } from 'react-bootstrap';
import TextField from '../text-field/text-field';
import React, { KeyboardEvent } from 'react';
import { useField, useFormikContext } from 'formik';
import { emailSchema } from '../../utils/formSchema';
import { ActivateAccountFormInput } from '../../interfaces/interfaces';

export function ActivateAccountEmailInput() {
  const { handleSubmit, isSubmitting } =
    useFormikContext<ActivateAccountFormInput>();
  const [, meta] = useField<string>('email');
  const { value } = meta;
  const isEmailValid = () => {
    return !isSubmitting && emailSchema.isValidSync({ email: value });
  };

  return (
    <>
      <div style={{ height: '38px' }}></div>
      <Row>
        <Col>
          <p className={'header'}>Activate your account</p>
          <p className={'subtext'}>Enter your email address</p>
          <TextField
            label="Email"
            name="email"
            onKeyUp={(event: KeyboardEvent) => {
              if (event.key === 'Enter' && isEmailValid()) {
                handleSubmit();
              }
            }}
          />
        </Col>
      </Row>
      <Row className={'form-button-row'}>
        <Col>
          <Button
            disabled={!isEmailValid()}
            color="primary"
            id="button-next"
            type={'submit'}
            style={{ width: '100%' }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ActivateAccountEmailInput;
