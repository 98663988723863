import { getToken } from '../../utils/cookie-store';
import { useSearchParams, Navigate } from 'react-router-dom';
import { isExpired } from '../../utils/jwt-utils';

export function Authorize() {
  const token = getToken();
  const [searchParams] = useSearchParams();

  const redirectUri = searchParams.get('redirect_uri');
  const state = searchParams.get('state');

  if (token && redirectUri && !isExpired(token)) {
    const url = `${redirectUri}#access_token=${encodeURIComponent(
      token
    )}&state=${state}`;
    window.location.assign(url);
    return null;
  }

  return <Navigate to={`/login?${searchParams.toString()}`} replace />;
}
