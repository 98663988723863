import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './components/login/login';
import ActivateAccount from './components/activate-account/activate-account';
import SetPassword from './components/set-password/set-password';
import SendEmailSuccess from './components/send-email-success/send-email-success';
import { Logout } from './components/logout/logout';
import { Authorize } from './components/authorize/authorize';
import { MainLayout } from './components/main-layout/main-layout';
import { ForgotPassword } from './components/forgot-password/forgot-password';

export function App() {
  return (
    <Routes>
      <Route
        path={'/'}
        element={<Navigate to={'/authorize'} replace={true} />}
      />
      <Route path={'/authorize'} element={<Authorize />} />
      <Route element={<MainLayout />}>
        <Route path={'/login'} element={<Login />} />
        <Route path={'/password'}>
          <Route path={'update'} element={<SetPassword />}></Route>
        </Route>
        <Route path={'/verify-email'} element={<SendEmailSuccess />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/activate-account'} element={<ActivateAccount />} />
      </Route>
      <Route path={'/v2'}>
        <Route path={'logout'} element={<Logout />} />
      </Route>
    </Routes>
  );
}

export default App;
