import styles from './set-password.module.scss';
import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { UpdatePasswordDocument, UserDocument } from '../../queries';
import { logger } from '../../utils/logging';
import { Button, Col, Row } from 'react-bootstrap';
import {
  lowercaseSchema,
  minimumLengthSchema,
  numberSchema,
  PasswordSchema,
  passwordSchema,
  uppercaseSchema,
} from '../../utils/formSchema';
import { ReactComponent as RoundCheckBox } from '../../../assets/icons/roundCheckBox.svg';
import PasswordField from '../password-field/password-field';
import { useMutation, useQuery } from '@apollo/client';

const initialValues = {
  password: '',
};

export function SetPassword() {
  const [searchParams] = useSearchParams();
  const [setPassword] = useMutation(UpdatePasswordDocument);
  const hash = searchParams.get('hash');
  const { data: userQueryData, loading: userQueryLoading } = useQuery(
    UserDocument,
    {
      variables: hash
        ? {
            hash,
          }
        : undefined,
      skip: !hash,
    }
  );

  const redirectUri = searchParams.get('redirect_uri');
  const clientId = searchParams.get('client_id');
  const from = searchParams.get('from');
  const email = userQueryData?.user?.email;

  const fromInvite = !!from && from === 'invite';

  const handleSubmit = async (
    values: PasswordSchema,
    formikHelpers: FormikHelpers<PasswordSchema>
  ): Promise<void> => {
    const { password } = values;
    if (!hash || !clientId || !redirectUri || !email) {
      formikHelpers.setErrors({
        password: 'Unexpected error.',
      });
      return Promise.resolve();
    }
    return setPassword({
      variables: {
        hash: hash,
        password,
      },
    })
      .then(({ data }) => {
        if (!data) {
          formikHelpers.setErrors({
            password: 'Unexpected error.',
          });
          return;
        }

        if (data.setPassword.__typename === 'UpdatePasswordErrors') {
          formikHelpers.setErrors({
            password: data.setPassword.message,
          });
          return;
        }

        window.location.assign(redirectUri);
      })
      .catch((error) => {
        logger.error(error);
        formikHelpers.setErrors({
          password: 'Unexpected error.',
        });
      });
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={passwordSchema}
      onSubmit={(values, formikHelpers) => {
        return handleSubmit(values, formikHelpers);
      }}
      validateOnMount={true}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form>
          <p className={'header'} data-testid="headline">
            {fromInvite ? 'Please set your password' : 'Set your new password'}
          </p>
          <div className={styles['name-container']}>
            <p>{userQueryData?.user.fullName}</p>
            <span>{userQueryData?.user.email}</span>
          </div>
          <PasswordField showFieldError />
          <Row className={'pt-3'}>
            <div className={styles['check-list']}>
              <div
                className={
                  minimumLengthSchema.isValidSync(values)
                    ? styles['checkbox-green']
                    : styles['checkbox']
                }
              >
                <RoundCheckBox className={styles['icon']} /> At least 8
                Characters
              </div>
              <div
                className={
                  numberSchema.isValidSync(values)
                    ? styles['checkbox-green']
                    : styles['checkbox']
                }
              >
                <RoundCheckBox className={styles['icon']} /> At least 1 number
              </div>
              <div
                className={
                  uppercaseSchema.isValidSync(values)
                    ? styles['checkbox-green']
                    : styles['checkbox']
                }
              >
                <RoundCheckBox className={styles['icon']} /> At least 1
                uppercase letter
              </div>
              <div
                className={
                  lowercaseSchema.isValidSync(values)
                    ? styles['checkbox-green']
                    : styles['checkbox']
                }
              >
                <RoundCheckBox className={styles['icon']} /> At least 1
                lowercase letter
              </div>
            </div>
          </Row>
          <Row className={'form-button-row'}>
            <Col>
              <Button
                disabled={isSubmitting || !isValid || userQueryLoading}
                type={'submit'}
                color="primary"
                style={{ width: '100%' }}
                data-testid="submit-password"
              >
                {fromInvite ? 'Save and continue' : 'Set Password'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default SetPassword;
